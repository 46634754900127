import { WINNING_NUMBERS_SUCCESS, WINNING_NUMBERS_REQUEST } from "../constants"
import initialState from "../initialState"

const INITIAL_STATE = initialState.winningNumbers

export default function (state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case WINNING_NUMBERS_SUCCESS: {
      return {
        ...state,
        pager: action.payload.pager,
        nid: action?.nid,
        rows: action.paging ? (state.rows || []).concat(action.payload.rows) : action.payload.rows,
        filtering: false,
      }
    }
    case WINNING_NUMBERS_REQUEST: {
      return {
        ...state,
        isLoading: action.payload,
        filtering: action?.filtering,
      }
    }
    default:
      return {
        ...state,
        filtering: false,
      }
  }
}