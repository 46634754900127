// Determine if we're developing locally
// If so, return the localhost port of the Drupal API from server
// Else return nothing and use a local path
const isLocalDev = process.env.GATSBY_DEV_ENV === 'local';
// Return https if we're on local HTTPS for the server
const isHTTPS = process.env.GATSBY_LOCAL_HTTPS === 'true';
const ip = 'localhost';

const devPath = isLocalDev ? `http${isHTTPS ? 's' : ''}://${ip}:8080` : '';

export const LOCAL_API_PATH = devPath;
export const GATSBY_API_BASE_URL = devPath + "/drupal-api";
export const GATSBY_API_DRIVEN_URL = devPath + "/nyl-api";
export const YOUTUBE_API = devPath + "/youtube-api";
export const SSO_BASE_URL = devPath + "/sso.nylservices.net";
export const GATSBY_GOOGLE_MAP_API_KEY =
  "AIzaSyBox9hg3SDLTqX4woAgIUqdLTgQ9cr9G78"
export const GATSBY_API_ITS_URL = devPath + "/emergency-updates";
export const GATSBY_RECAPTCHA_V3_SITE_KEY="6LdZ3JApAAAAAJjSIc83aPKuGQ09kYmzUrlioOrq";
