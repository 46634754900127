import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import duration from 'dayjs/plugin/duration';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);
dayjs.extend(duration);

export const getDurationFromNow = timestamp => {
  return dayjs.duration(dayjs(timestamp).diff());
}

/**
 * Convert a time to eastern time.
 * 
 * @param {number | string} time 
 * @returns DayJS
 */
export function easternTime(time) {
  return dayjs.tz(time, 'America/New_York');
}

/**
 * Format a time to a full date format.
 * Example: Sat 04/17/23
 * 
 * @param {number | string} time 
 * @returns string
 */
export function dayAndDateFormatted(time) {
  return easternTime(time).format('ddd MM/DD/YY');
}

/**
 * Format a time to a full month day year format.
 * Example: February 17, 2023
 * 
 * @param {number | string} time 
 * @returns string
 */
export function fullMonthDayYear(time) {
  return easternTime(time).format('MMMM DD, YYYY');
}

/**
 * Format a time to a localized month day year format.
 * Example: 02/17/2023
 * 
 * @param {number | string} time 
 * @returns string
 */
export function localizedMonthDayYear(time) {
  return easternTime(time).format('L');
}

/**
 * Format a time to a localized time format.
 * Example: 3:14 PM
 * 
 * @param {number | string} time 
 * @returns string
 */
export function localizedTime(time) {
  return easternTime(time).format('LT');
}

/**
 * Format a time to a shortened month/day/year format.
 * Example: 02/17/23
 * 
 * @param {number | string} time
 * @returns string 
 */
export function monthDayYearShort(time) {
  return easternTime(time).format('MM/DD/YY');
}

/**
 * Format a time to a month/day/year format with a full year.
 * Example: 02/17/2023
 * 
 * @param {number | string} time 
 * @returns string
 */
export function monthDayYearLong(time) {
  return easternTime(time).format('MM/DD/YYYY');
}

/**
 * Format a time to a month and month day with ordinal format.
 * Example: February 17th
 * 
 * @param {number | string} time 
 * @returns string
 */
export function monthAndMonthDayFormatted(time) {
  return easternTime(time).format('MMMM Do');
}

/**
 * Format a time to a full month.
 * Example: February
 * 
 * @param {number | string} time 
 * @returns string
 */
export function fullMonthFormatted(time) {
  return easternTime(time).format('MMMM');
}

/**
 * Format a time to a two digit month day.
 * Example: 06
 * 
 * @param {number | string} time 
 * @returns string
 */
export function twoDigitMonthDayFormatted(time) {
  return easternTime(time).format('DD');
}

/**
 * Format a time to an abbreviated month name.
 * Example: Feb
 * 
 * @param {number | string} time 
 * @returns string
 */
export function abbrevMonthFormatted(time) {
  return easternTime(time).format('MMM');
}

/**
 * Format a time to an abbreviated day of the week.
 * Example: Fri
 * 
 * @param {number | string} time 
 * @returns string
 */
export function abbrevDayOfWeek(time) {
  return easternTime(time).format('ddd');
}

/**
 * Format a time to a year format.
 * Example: 2023
 * 
 * @param {number | string} time 
 * @returns string
 */
export function yearFormatted(time) {
  return easternTime(time).format('YYYY');
}
