import { INITIAL_STATE as liveShow } from "./reducers/liveShowReducer"
import { initialState as i18nReducer } from "./reducers/i18nReducer"
const initialState = {
	messageResult: {},
	app: {
		barcode: '',
		pageIds: {},
		contents: [],
		footer: {
		},
		stickyFooters: [],
		page: {},
		dynamicpage: {},
		pages: {},
		emailErr: {},
    appView: false
	},
	promotions: [],
	events: [],
	secondChance: [],
	winners: {
		all: [],
		featured: {},
		recent: {},
	},
	currentWinner: {},
	scratchOffGame: {
		games: [],
		allGames: [],
		pager: {}	//Pager is for scratch off games data not promoted data.
	},
	scratchOffGameDetail: null,
	scratchOffGamesReport: null,
	categories: [],
	topics: [],
	cmsGames: [],
	whereToWatch: [],
	countyBreakdown: [],
	regions: [],
	prizeClaimCenter: [],
	serviceCenter: [],
	winningNumbers: {
		isLoading: 'idle',
		nid: null,
	},
	alert: {},
	adminAlert: {
		opened: true,
	},
	sso: {
		accessToken: '',
		refreshToken: '',
		expiresIn: 0,
		opened: true,
	},
	ticketScan: {
		showModal: false,
		scanMyTickets: false,
		tutorialViewed: false,
		resultsLoading: false,
		camPermission: false,
		fuelCMS: {
			scans: {
				total: 0,
				remaining: 0,
				isEnabled: false,
				isUnlimitedScan: true
			}
		}
	},
	bacon: {},
	liveShow,
	i18nReducer,
	stringByName: {
		strings: [],
		isLoading: false,
		error: null
	},
	contactFormReducer:{
		isSubmitting:false,
		success:false,
		successMessage:null,
		error:null
	},
	loading: {
		loadingItems: [],
		loadedItems: [],
	},
	scratchOffGamesMarquees: {
		data: [],
	},
	tickers: [],
  integrity: {
    security: {

    },
    trust: {

    }
  }
}

export default initialState
