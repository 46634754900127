/**
 * Determine if a link is going to an external website
 * e.g. is not a relative link and does not link to nylottery.ny.gov
 */
const acceptableURLS = ['nylottery.ny.gov', 'localhost'];
export function isExternalLink(url) {
  try {
    const parsedURL = new URL(url);
    return !acceptableURLS.find(item => parsedURL?.host?.includes(item));
  } catch (err) {
    // Most likely a relative link
    return false;
  }
}

export function isRelativeURL(url) {
  if (typeof url !== 'string') return false;
  try {
    new URL(url);
    return false;
  } catch (err) {
    if (url?.startsWith('//')) return false;
    // Relative link
    return true;
  }
}

// Given a full URL (e.g. https://nylottery.ny.gov/page/legal)
// get the relative URL (e.g. /page/legal)
export function getRelativeURL(fullURL) {
  try {
    const parsed = new URL(fullURL);
    return parsed?.href?.replace(parsed?.origin || '', '');
  } catch (err) {
    return fullURL;
  }
}